<template>
    <div v-if="src" id="acuity-scheduler" :key="src">
        <iframe
            :src="src"
            width="100%"
            :height="height"
            title="Schedule Appointment"
        >
            Loading...
        </iframe>
        <component
            :is="'script'"
            src="https://embed.acuityscheduling.com/js/embed.js"
            type="text/javascript"
            async
        />
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { Watch } from '@/utils/decorators';
import { extractor } from './Acuity';

const AcuityProps = Vue.extend({
    name: 'Acuity',
    props: {
        code: {
            type: String,
            default() {
                return '';
            }
        }
    }
});

@Component
export default class Acuity extends AcuityProps {
    @Watch('code')
    onCodeChange() {
        this.parse();
    }

    src = '';
    width = '100%';
    height = 0;

    mounted() {
        this.parse();
    }

    parse() {
        if (this.code) {
            this.reset();

            const { src, height } = extractor(this.code);

            if (src) {
                this.src = src;

                if (height) {
                    this.height = height;
                }
            }
        } else {
            this.reset();
        }
    }

    reset() {
        this.src = '';
        this.width = '100%';
        this.height = 0;
    }
}
</script>

<style lang="scss" scoped>
iframe {
    border: none;
    margin: 0;
    padding: 0;
}
</style>
