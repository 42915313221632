<template>
    <div v-if="dataUrl" id="calendly-scheduler" :key="dataUrl">
        <component
            :is="'script'"
            src="https://assets.calendly.com/assets/external/widget.js"
            type="text/javascript"
            async
        />
        <div
            class="calendly-inline-widget"
            data-resize="true"
            :data-url="dataUrl"
            :style="style"
        ></div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { Watch } from '@/utils/decorators';
import { extractor } from './Calendly';

const CalendlyProps = Vue.extend({
    name: 'Calendly',
    props: {
        code: {
            type: String,
            default() {
                return '';
            }
        }
    }
});

@Component
export default class Calendly extends CalendlyProps {
    @Watch('code')
    onCodeChange() {
        this.parse();
    }

    dataUrl = '';
    style = 'min-width: 320px; height: 700px';

    mounted() {
        this.parse();
    }

    parse() {
        if (this.code) {
            this.reset();

            const { dataUrl, style } = extractor(this.code);

            if (dataUrl) {
                this.dataUrl = dataUrl;
            }

            if (style) {
                this.style = style;
            }
        } else {
            this.reset();
        }
    }

    reset() {
        this.dataUrl = '';
        this.style = '';
    }
}
</script>
