<template>
    <div>
        <acuity v-if="is('acuity')" :code="options?.scheduler?.acuity" />
        <calendly v-if="is('calendly')" :code="options?.scheduler?.calendly" />
        <hub-spot v-if="is('hubspot')" :code="options?.scheduler?.hubspot" />
        <google-calendar
            v-if="is('googlecalendar')"
            :code="options?.scheduler?.googlecalendar"
        />
    </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import Acuity from './Acuity.vue';
import Calendly from './Calendly.vue';
import HubSpot from './HubSpot.vue';
import GoogleCalendar from './GoogleCalendar.vue';

import type { ClientCabinOptions, SchedulerType } from '@/types/ClientCabin';

const SchedulerProviderProps = Vue.extend({
    name: 'SchedulerProvider',
    props: {
        options: {
            type: Object as PropType<ClientCabinOptions>,
            default() {
                return {
                    default_scheduler: null,
                    scheduler: {
                        calendly: '',
                        hubspot: '',
                        acuity: '',
                        googlecalendar: ''
                    }
                };
            }
        }
    }
});

@Component({
    components: {
        Acuity,
        Calendly,
        HubSpot,
        GoogleCalendar
    }
})
export default class SchedulerProvider extends SchedulerProviderProps {
    is(provider: SchedulerType) {
        return this.options.default_scheduler === provider;
    }
}
</script>
