<template>
    <div v-if="dataSrc" id="hubspot-scheduler" :key="dataSrc">
        <div class="meetings-iframe-container" :data-src="dataSrc"></div>
        <component
            :is="'script'"
            src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
            type="text/javascript"
            charset="utf-8"
        />
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { Watch } from '@/utils/decorators';
import { extractor } from './HubSpot';

const HubSpotProps = Vue.extend({
    name: 'HubSpot',
    props: {
        code: {
            type: String,
            default() {
                return '';
            }
        }
    }
});

@Component
export default class HubSpot extends HubSpotProps {
    @Watch('code')
    onCodeChange() {
        this.parse();
    }

    dataSrc = '';

    mounted() {
        this.parse();
    }

    parse() {
        if (this.code) {
            this.reset();

            const { dataSrc } = extractor(this.code);

            if (dataSrc) {
                this.dataSrc = dataSrc;
            }
        } else {
            this.reset();
        }
    }

    reset() {
        this.dataSrc = '';
    }
}
</script>
